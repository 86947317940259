import '../HomePage/HomePage.css'

const FooterPage = () => {
    //rendering
    return(
        <div className="footer-fluid-container">
            <div className="footer-centered-container">
                <div className="footer-row">
                    <div className="association-address">
                        <ul>
                            <li>LA CROISADE DE LA PRIERE</li>
                            {/* 
                            <li>151, rue de savoie</li>
                            <li>93290 Tremblay-en-France</li>*/}
                            <li>Tel: +33 06 52 01 69 97</li>
                            <li><a href="/contact">Formulaire de contact...</a></li>
                        </ul>                        
                    </div>
                </div>
                <div className="footer-row">
                    <div className="copyright">&copy;2021-2024 JÉSUS EST LA RÉPONSE</div>
                </div>
            </div>
        </div>
    )
}

export default FooterPage;