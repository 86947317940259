
import React, { Component } from 'react';
import {Route, BrowserRouter, Routes} from "react-router-dom"
import FooterPage from './Components/FooterPage/FooterPage';
import HomePageView from "./Views/HomePageView";
import RequestView from "./Views/RequestView";
import MailingView from "./Views/MailingView";
import AuthentView from "./Views/AuthentView";
import SocialShareView from "./Views/SocialShareView";
import ContactView from "./Views/ContactView"
import UserLounge from './Components/UserLounge/UserLounge'
import StickyMenu3 from "./Components/StickyMenu3/StickyMenu3";
import ChatbotForm from './Components/ChatbotForm/ChatbotForm';
import DailyWorshipView from './Views/DailyWorshipView'
import BookletsView from './Views/BookletsView'
import AboutPageView from "./Views/AboutPageView";
import SubscribeView from "./Views/SubscribeView";
import ParticipationView from "./Views/ParticipationView";
import BiblePageView from "./Views/BiblePageView"
import "./Components/HomePage/HomePage.css";
import { globals, onClickAssistantHelper } from "./Components/Globals/Globals";


class App extends Component {
  constructor(props){
    super(props);
    this.state = {collapsed:true, chatbot_help_collapsed:globals.parameters.assistant_helper_collapsed}
  }

  toggleNavbar = () => this.setState({collapsed:!this.state.collapsed});

  render = () =>{
    return (
      <BrowserRouter>
        <div id="menu">
            <StickyMenu3/>
        </div>
        
        {/* Router page content */}
        <div className="content">
        <Routes>
          <Route exact path = "/" element ={<HomePageView {...this.props}/>} />
          <Route exact path = "/authentview" element={<AuthentView {...this.props}/>} /> {/* Route without parameter */}
          <Route path = "/userlounge/:uuidrequetes" element={<UserLounge {...this.props}/>} /> {/* Route with parameters */}
          <Route path = "/mailingview/:uuidrequetes" element={<MailingView {...this.props}/>} /> {/* Route with parameters */}
          <Route path = "/priere" element={<RequestView {...this.props}/>} />
          <Route path = "/socialshare" element={<SocialShareView {...this.props}/>} />
          <Route path = "/dailyworship" element={<DailyWorshipView {...this.props}/>} />
          <Route path = "/contact" element={<ContactView {...this.props}/>} />
          <Route path = "/booklets" element={<BookletsView {...this.props}/>} />
          <Route path = "/about" element={<AboutPageView {...this.props}/>} />
          <Route path = "/subscription" element={<SubscribeView {...this.props}/>}/>
          <Route path = "/participation" element={<ParticipationView {...this.props}/>}/>
          <Route path = "/bibleread" element={<BiblePageView {...this.props}/>} />
        </Routes>
        </div>
          <ChatbotForm
            headerTitle="Assistant de requête"
            opened={false}
          />
          <div className={`help-assist ${this.state.chatbot_help_collapsed?"assist-close":""}`} onClick={(e)=>{onClickAssistantHelper(e)}}>Puis-je vous aider ?</div>

        {/* Footer */}
        <FooterPage/>
      </BrowserRouter>
    );
  }
}

export default App;
