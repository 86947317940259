import React, {useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Component } from 'react';
import "./Thanks.css";

class Thanks extends Component{
    constructor (props) {
        super(props);
        this.state = ({thanks_text:null, hasChanged:false})
    }

    componentDidMount = () =>{
        this.onThanksChange = this.onThanksChange.bind(this);
        this.thanks_service_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
        ?'http://localhost:4007'
        :`https://thanks-service.jesusestlareponsepourtous.org`;
        this.fetchTanks();
    }

    fetchTanks = async () => {
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        const fetchresult = await axios.post(`${this.thanks_service_url}/last-thanks`, {idrequetes:this.props.idrequetes});
        this.setState({thanks_text : (fetchresult.data.items.length > 0)?fetchresult.data.items[0].texte_remerciement:"Rédigez ici votre premier remerciement."});
    };

    saveThanks = async () => {
        //call requete-service
        var serviceResult = null
        try{
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            serviceResult = await axios.put(`${this.thanks_service_url}/thanks-add`,
                                {idrequetes:this.props.idrequetes, 
                                    texte_remerciement:this.state.thanks_text});
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }

        //Get service aknowledge before resetting state values.

        if (serviceResult.data.state === true){
            this.setState({hasChanged:false});
            alert("Texte de remerciement enregistré !")
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    }

    onThanksChange = e =>{
        e.preventDefault();

        this.setState({thanks_text:e.target.value , hasChanged:true})
    }

    render = () => {
        return( 
            <div>
                {/*!this.state.thanks_text && <i>Vous n'avez pas encore exprimé de remerciements...</i>*/}
                <div>
                    <textarea 
                        className="thanks-text-area"
                        placeholder="Entrez votre remerciement" 
                        onChange={this.onThanksChange} 
                        rows="4" value={this.state.thanks_text} maxlength="500"/>
                    <br/>
                    <Button color="primary" onClick={this.saveThanks} disabled={!this.state.hasChanged}>Enregistrer le remerciement</Button>
                </div>
            </div>
        )
    }
}

export default Thanks;
