/*
AuthentView
*/

import React, {Component, useEffect, useState} from 'react';
import { Button } from 'reactstrap'
import SocialShare from '../Components/SocialShare/SocialShare';
import {globals} from '../Components/Globals/Globals'
import ReactPlayer from 'react-player';

const debug = false

const SocialShareView = () => {
    //rendering
    return (

        <div className="social-page-container">
            {/* Full width main picture */}
            <div className="top-banner-image">
                <div className="nested-title dark">{globals.menu.donate}</div>
            </div>

            <div className="body-page-container">
                {/* Laïus introduction de la page*/}
                <div className="welcome-title">
                    <div>
                        Vous pouvez nous soutenir et nous aider à mieux servir les autres…
                    </div>
                </div>
                {/* ------------ VIDEO TRAILER ------------- */}
                <div className="top-event-trailer-container">
                    <div className='player-wrapper'>
                        <ReactPlayer
                                url="https://www.jlrpt.com/video/prions_ensemble.mp4"
                                className={'react-player-classname'}
                                playing={true}
                                controls={true}
                                light={"https://www.jlrpt.com/picture/prions_light_snapshot.png"}
                                pip={true}
                                width={"100%"}
                                height={"100%"}
                            />
                    </div>
                </div>

                {/* ---- Social networks support ----- */}
                <div className="welcome-sub-title">
                    <div>
                        Par la communication sur les réseaux sociaux.
                    </div>
                </div>

                {/* Laïus */}
                <div className="introduction-container">
                    <p>Vous avez été bénis par Dieu au travers du ministère de la Croisade De La Prière, vous êtes en contact avec des personnes qui ont besoin d’intercession, n’hésitez pas à en parler autour de vous. Le bouche à oreille est une méthode qui fonctionne encore très bien.<br/>
                    Nous vous invitons également à utiliser les moyens modernes de communication pour faire connaître notre site internet, pour donner de l’espoir à ceux qui en ont besoin. <br/>
                    <b>Merci pour le partage !</b>
                    </p>
                </div>
                
                <div className="buttons-panel">
                    <SocialShare />
                </div>    

                {/* ----- Prayers support ----- */}
                <div className="welcome-sub-title">
                    <div>
                        Par la prière.
                    </div>
                </div>

                
                <div className="introduction-container">
                    <p>Certains sont fortifiés, libérés de leurs angoisses ou encore guéris ou soulagés de leurs maux. D’autres encore trouvent des solutions à leurs problèmes ou à leurs défis. Nombreux sont ceux qui se réveillent et s’engagent pour servir Dieu et l’humanité.<br/> 
                    <b>Prier c’est la clé !</b>
                    Soutenez-nous par la prière persévérante de la foi pour que Dieu nous donne du succès dans notre mission. <br/>
                    <b>Merci pour vos prières !</b>
                    </p>
                </div>
                
                {/* ----- Material  donation ----- */}
                <div className="welcome-sub-title">
                    <div>
                    Par des moyens matériels et financiers.
                    </div>
                </div>
                
                <div className="introduction-container">
                    <p>
                    <b>La&nbsp;Croisade&nbsp;De&nbsp;La&nbsp;Prière</b> a besoin de matériels informatiques et audiovisuels pour atteindre plus de personnes (des ordinateurs, des caméras, des écrans, etc...). 
                    Grâce à votre soutien financier, elle pourra distribuer de la littérature (prospectus, ouvrages, Bibles) et réaliser des conférences, des séminaires, des concerts d’évangélisation, des festivals (de prières, de louanges, de témoignages, de la Bible…). 
                    <br/><br/>
                    Nos objectifs financiers se déclinent comme suit :<br/>
                    <ul><li><b>OBJECTIF N°1 EST DE 30 000 €</b> pour réaliser en 2023 des actions et événements d’intercession et d’évangélisation ;</li>
                    <li><b>OBJECTIF N°2 EST DE 100 000 €</b> pour l’acquisition de matériels informatiques et audiovisuels ;</li>
                    <li><b>OBJECTIF N°3 EST DE 70 000 €</b> pour le fonctionnement (abonnements mensuels, les droits SACEM, INSI, réalisation, entretien et redevance de notre site internet…) et le salaire d’un employé.</li>
                    </ul>
                    </p>
                </div>
                
                
                <div className="introduction-container">
                    <p>
                    Le soutien le plus infime donné avec amour et provenant d’un cœur généreux est d’une grande valeur devant Dieu. Comme le dit la Bible&nbsp;:
                    <br/> « Que chacun donne comme il l’a résolu en son cœur, sans tristesse ni contrainte ; car Dieu aime celui qui donne avec joie. » <br/><i>(2 Corinthien 9.7)</i>. 
                    </p>
                </div>   

                <div className="introduction-container">
                <div className="donate-columns">
                    <div className='donate-item by-cheque'>
                        Vous pouvez envoyer un chèque à l’ordre de la Croisade De La Prière à l’adresse suivante&nbsp;:
                        <div className='emphasis'>151 rue de Savoie, 93290
                        Tremblay-en-France</div>
                    </div> 
                    <div className='donate-item by-virement'>
                        Vous pouvez effectuer un virement sur le compte de La Croisade De La Prière&nbsp;:
                        <div className='emphasis'>IBAN : FR64 3000 2014 4900 0007 3088 D64</div>
                    </div> 
                    <div className='donate-item by-internet'>
                        Vous pouvez aussi passer par notre intermédiaire en ligne&nbsp;:
                        
                        <div className='emphasis'>
                            <a className='hello-asso' target="_blank" href="https://www.helloasso.com/associations/la-croisade-de-la-priere/formulaires/1"></a>
                        </div>
                    </div>
                </div> 
                </div>
                
            </div>    
        </div>
        );
}

export default SocialShareView;