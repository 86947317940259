import React, { useState } from 'react'
import "./StickyMenu3.css"
import {globals} from '../Globals/Globals'

function StickyMenu3 (props) {
    let navbar_top_ref = React.createRef();
    let navbar_intitial_offset_top = -1;
    let [Toggle,setToggle] = useState(false);
    let [Collapsed,setCollapsed] = useState(true);
    //let [navBackColor,setNavBackColor] = useState(props.navBackColor?props.navBackColor:"transparent");
    let [navBackColor,setNavBackColor] = useState(props.navBackColor?props.navBackColor:"black");//Force back color to black
    let [scrollTriggerCeil,setScrollTriggerCeil] = useState(props.scrollTriggerCeil?props.scrollTriggerCeil:40);

    React.useEffect(
        () => {
            window.addEventListener('scroll', function() {
                if (navbar_top_ref.current){
                    if (navbar_intitial_offset_top == -1)
                        navbar_intitial_offset_top = navbar_top_ref.current.offsetTop;
                    
                    if (globals.parameters.sticky3_debug){
                        console.clear()
                        console.log("window.scrollY => ", window.scrollY)
                        console.log("navbar_intitial_offset_top => ", navbar_intitial_offset_top)
                        console.log("navbar_top_ref.current.offsetTop => ", navbar_top_ref.current.offsetTop)
                    }

                    //Stick process
                    if (window.scrollY > navbar_intitial_offset_top) {
                        navbar_top_ref.current.classList.add('fixed-menu-top');

                        if (!props.auto_padding){
                            // add padding top to show content behind navbar
                            let navbar_height = navbar_top_ref.current.offsetHeight;
                            document.body.style.paddingTop = navbar_height + 'px';
                        }
                    } else {
                        navbar_top_ref.current.classList.remove('fixed-menu-top');

                        // remove padding top from body
                        if (!props.auto_padding) document.body.style.paddingTop = '0';
                    }

                    //Back Color process
                    if (window.scrollY > scrollTriggerCeil) {
                        //Change back color as scroll position is greater than nav bar heigth

                        //Set scrollabled background color
                        if (globals.parameters.sticky3_debug)
                            console.log("set Opaque")
                            
                        setNavBackColor(props.navBackScrolledColor?props.navBackScrolledColor:"black");
                    } else {
                        //Restore initial background color
                        if (globals.parameters.sticky3_debug)
                            console.log("set Transparent")

                        //setNavBackColor(props.navBackColor?props.navBackColor:"transparent");
                        setNavBackColor(props.navBackColor?props.navBackColor:"black");//Force back color to black
                    }
                }
            });
        }
    )

    const rendering = () => {
        return(
            <>
                <nav 
                    ref={navbar_top_ref} 
                    id="navbar_top" 
                    class="navbar navbar-expand-lg navbar-dark"
                    style={{backgroundColor:`${navBackColor}`}}
                    >
                    <div class="container">
                        <a class="navbar-brand" href="/">Jésus est la réponse</a>
                        <button onClick={()=>{setCollapsed(!Collapsed);}} class={`navbar-toggler ${Collapsed?"collapsed":""}`} type="button" data-toggle="collapse" data-bs-target="#main_nav" aria-expanded={`${Toggle?"true":"false"}`} aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class={`collapse navbar-collapse ${Collapsed?"collapse":"collapse show"}`} id="main_nav">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item"><a class="nav-link" href="/">Accueil</a></li>
                                <li class="nav-item"><a class="nav-link" href="/dailyworship">{globals.menu.daily_worship}</a></li>
                                <li class="nav-item"><a class="nav-link" href="/priere">Soumettre une requête</a></li>
                                <li class="nav-item"><a class="nav-link" href="/authentview">Mon espace personnel</a></li>
                                <li class="nav-item"><a class="nav-link" href="/bibleread">{globals.menu.bible_read}</a></li>
                                {globals.parameters.local &&
                                <li class="nav-item"><a class="nav-link" href="/booklets">{globals.menu.booklets}</a></li>}

                                <li class="nav-item dropdown" >
                                    <a class={`nav-link dropdown-toggle pointer ${Toggle ? "show" : ""}`} data-toggle="dropdown" onClick={() => {setToggle(!Toggle); }}>La mission</a>
                                    <ul class={`dropdown-menu dropdown-menu-right ${Toggle ? "show" : ""}`}>
                                        
                                        <li class="nav-item"><a class="dropdown-item" href="/socialshare">{globals.menu.donate}</a></li>
                                        {globals.parameters.festival_enabled &&
                                        <li class="nav-item"><a class="dropdown-item" href="/participation">{globals.menu.participation}</a></li>}
                                        <li class="nav-item"><a class="dropdown-item" href="/subscription">{globals.menu.subscribe}</a></li>
                                        <li class="nav-item"><a class="dropdown-item" href="/contact">Nous contacter</a></li>
                                        <li class="nav-item"><a class="dropdown-item" href="/about">{globals.menu.about_us}</a></li>
                                    </ul>
                                </li>
                            </ul>

                        </div> {/*<!-- navbar-collapse.// -->*/}
                    </div> {/*<!-- container-fluid.// -->*/}
                </nav>
            </>
        )
    } 

    return(
        <>
            {rendering()}
        </>
    )
}

export default StickyMenu3;