import { Component  } from "react";
import HomePage from "../Components/HomePage/HomePage";

class HomePageView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <>
              <HomePage/>  
            </>
        )
    }
}

export default HomePageView;