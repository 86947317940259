import { Component  } from "react";
import {ModalBody} from 'reactstrap'
import ParticipationForm from "../Components/ParticipationForm/ParticipationForm";
import {globals} from "../Components/Globals/Globals"

class SubscribeView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="participation-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <div className="nested-title">{globals.menu.participation}</div>
                </div>
                <div className="body-page-container">
                    {/* Laïus introduction de la page*/}
                    <div className="welcome-title">
                        <div>
                            Vous souhaitez participer au festival de la prière, inscrivez-vous !
                        </div>
                    </div>
                    <ModalBody>
                        <ParticipationForm/>  
                    </ModalBody>
                </div>
            </div>
        )
    }
}

export default SubscribeView;