import { Component  } from "react";
import AboutPage from "../Components/AboutPage/AboutPage";

class AboutPageView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <>
              <AboutPage/>  
            </>
        )
    }
}

export default AboutPageView;