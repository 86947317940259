import { Button } from "reactstrap";
import { Component  } from "react";
import RequeteForm2 from "../Components/RequeteForm2/RequeteForm2";
import { globals, onClickAssistantHelper } from "../Components/Globals/Globals";
import {Route, NavLink, Switch, BrowserRouter, useNavigate} from "react-router-dom"

class RequestView extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="request-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <div className="nested-title">Soumettre une requête</div>
                </div>

                <div className="body-page-container">
                    {/* Welcome Panel */}
                    <div className="welcome-title">
                        <div>
                            Formulaire de Soumission d'une requête d'intercession.
                        </div>
                    </div>

                    {/* Laïus introduction de la page*/}
                    <div className="introduction-container">
                        <p>Veuillez remplir le formulaire de soumission de votre sujet de prière. Notre équipe intercèdera pour vous dans une atmosphère de jeûne et de prière, et portera ainsi votre demande sur le trône de la grâce.
                        <br/>Nous prierons !</p>
                        <hr/>
                        <p><strong>Nouveau</strong> Vous soumettre rapidement vos requêtes en passant par notre assistant&nbsp;!</p>
                        <Button onClick={(e)=>{onClickAssistantHelper(e)}}>Je soumets par l'assistant</Button>
                        <hr/>
                    </div>
                    <div className="introduction-container">
                    </div>

                    <RequeteForm2/>  
                </div>
            </div>
        )
    }
}

export default RequestView;