/*
AuthentView
*/

import React, {Component, useEffect, useState} from 'react';
import { Button } from 'reactstrap'
import SocialShare from '../Components/SocialShare/SocialShare';
import {globals} from '../Components/Globals/Globals'
import DailyWorshipText from '../Components/DailyWorshipText/DailyWorshipText';

const DailyWorshipView = () => {
    //rendering
    return (
        <div className="worship-page-container">
            {/* Full width main picture */}
            <div className="top-banner-image">
                <div className="nested-title dark">{globals.menu.daily_worship}</div>
            </div>

            <div className="body-page-container">
                {/* Laïus introduction de la page*/}
                <div className="welcome-title">
                    <div>
                        Prions ensemble …
                    </div>
                </div>

                {/*---- Worship Title ----*/}
                <div className='daily-worship-container'>
                    <DailyWorshipText/>
                </div>
                
               
                <div className="buttons-panel">
                    <p>Vous êtes touchés, par la médiation du jour&nbsp;? Partagez là&nbsp;!</p>
                    <SocialShare 
                        url={globals.share.worship.url}
                        description={globals.share.worship_description}
                        />
                </div>    
                
                
                <div className="introduction-container">
                    <p>
                    Vous désirez recevoir chaque jour une prière, abonnez vous...
                    </p>
                        <a class="rounded-button classic-button" href="/contact">Je m'abonne...</a>
                </div>   
                
            </div>    
        </div>
        );
}

export default DailyWorshipView;