import React, { Component } from 'react'
import NestedTitlePicture from "../NestedTitlePicture/NestedTitlePicture"
import CoordinateursPicture from '../../Assets/img/coordinateurs.jpg'
import RestranscripteursPicture from '../../Assets/img/retranscripteurs.jpg'
import AnimateursPicture from '../../Assets/img/animateurs.jpg'
import IntercesseursPicture from '../../Assets/img/intercesseurs.jpg'

import SantePicture from '../../Assets/img/healthy.jpg'

class Acteurs extends Component{
    constructor(props){
        super(props)
    }

    staticRender = () => {
        let title_color = "white"
        let title_font_size = "larger"
        let container_width = "100%"
        let container_height = "150px"

        return (
            <div className="acteurs-columns-container">
                <div className="acteur-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Les retranscripteurs"}
                        pictureURL={RestranscripteursPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="acteur-description">
                    L’équipe de retranscription a en charge la gestion des requêtes reçues par mail. Les requêtes transmises font l’objet d’une synthèse, d’une reformulation, d’une rédaction en vue de l’élaboration d’une liste de sujets de prière permettant des moments d’intercession de qualité. Enfin, l’équipe conçoit divers documents et supports.
                    </div>
                </div>

                {/*
                <div className="acteur-column">
                    <img src={SantePicture}></img>
                    <div className="acteur-name">Test</div>
                    <div className="acteur-description">
                    L’équipe de retranscription a en charge la gestion des requêtes reçues par mail. Les requêtes transmises font l’objet d’une synthèse, d’une reformulation, d’une rédaction en vue de l’élaboration d’une liste de sujets de prière permettant des moments d’intercession de qualité. Enfin, l’équipe conçoit divers documents et supports.
                    </div>
                </div>*/}

                <div className="acteur-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Les coordinateurs"}
                        pictureURL={CoordinateursPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="acteur-description">
                    L’équipe des Coordinateurs sert d’interface entre l’Administrateur des groupes WhatsApp et les Intercesseurs. Chaque Coordinateur a un binôme pour assurer la gestion d’un groupe.<br/>
                    Leur fonction principale consiste à relayer, entre autres, les informations telles que les listes des sujets de prière, à dynamiser et à favoriser la cohésion des groupes.
                    </div>
                </div>
                
                <div className="acteur-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Les animateurs"}
                        pictureURL={AnimateursPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="acteur-description">
                    Les Animateurs gèrent les réunions de prière en ligne via l’application Zoom. Ils assurent la méditation quotidienne et contribuent ainsi à l’édification et à la croissance spirituelle de chaque intercesseur.
                    </div>
                </div>

                <div className="acteur-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Les intercesseurs"}
                        pictureURL={IntercesseursPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="acteur-description">
                    Les Intercesseurs sont répartis dans dix cellules WhatsApp d’intercession et de soutien. Dévoués à la prière, ils participent aux différents moments d’intercession en ligne, par le biais des listes de prière.<br/>
                    Ces listes sont envoyées deux fois par semaine, le dimanche et le mercredi, dans les groupes WhatsApp correspondants afin de permettre à chacun d’intercéder.
                    </div>
                </div>         
            </div>
        )
    }

    render = () => {
        return(
            this.staticRender()
        )
    }
} 

export default Acteurs;