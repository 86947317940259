import React, { Component } from 'react'
import {Route, NavLink, Switch, BrowserRouter, useNavigate} from "react-router-dom"
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ActeursPage from '../ActeursPage/ActeursPage'
import NestedTitlePicture from '../NestedTitlePicture/NestedTitlePicture'
import '../HomePage/HomePage.css'

import LoungePicture from '../../Assets/img/lounge.jpg'
import pasteur_face from '../../Assets/img/pasteur_face.jpg'
import worship_picture from '../../Assets/img/worship_with_us.jpg'
import {globals} from '../Globals/Globals'

class AboutPage extends Component{
    constructor(props){
        super(props)
        this.debug = false
    }

    render = () => {
        return(
            <div className="about-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <div className="nested-title dark">
                        <div>{globals.menu.about_us}</div>
                        <a class="rounded-button transparent-button" href="/dailyworship">{globals.menu.daily_worship}</a>
                    </div>
                </div>
                <div className="body-page-container">
                    {/* Welcome Panel */}
                    <div className="welcome-title">
                        <div>
                            La Croisade de la Prière - Association loi 1901
                        </div>
                    </div>
                    
                    {/* Laïus d'introduction du Pasteur*/}
                    <div className="introduction-container">
                      
                        <div className="introduction-columns-container">
                            <div className="introduction-column">
                                <div className="intro-title">Le mot du pasteur</div>
                                <img src={pasteur_face} width="100%"/>
                            </div>
                            <div className="introduction-column">
                                Nous vivons une période unique dans l’histoire de l’humanité. Jamais auparavant, nous n’avons eu autant de preuves de la seconde venue de Jésus-Christ. Parmi ces signes qui interpellent, il y a la souffrance autour de nous qui témoigne que le monde a un immense besoin de guérison et de délivrance.
                                <div className="signature">Samuel Saint-Elie.</div>
                            </div>
                            <div className="introduction-column">
                                <div className="intro-title">La mission</div>
                                La « Croisade de la Prière » est une chambre haute et un ministère d'intercession en ligne qui se déroulent tous les matins de 6h à 6h30 ainsi que les dimanches et mardis soirs de 20h30 à 21h par le biais de la plateforme Zoom.
                            </div>
                        </div>
                    </div>

                    {/* Acteurs */}
                    <div className="acteurs-fluid-container">
                        <div className="acteurs-container">
                            <div className="acteurs-title">Les acteurs</div>
                            <p className="acteurs-introduction">
                                Chacun des sujets de prières qui nous sont confiés, sont traités avec soin. Depuis le dépôt sur le site jusque sur le trône de la grâce, nous acheminons vos sujets dans le respect, la confidentialité et la consécration des membres de l'équipe.
                            </p>
                            <ActeursPage/>
                        </div>
                    </div>

                    {/* News */}
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Suivre sa requête</div>
                            {/* Latest news */}
                            <div className="news-columns-container">
                                <div className="news-column">
                                    <NestedTitlePicture
                                        height={"100%"} 
                                        pictureTitle={"Mon espace personnel"}
                                        pictureURL={LoungePicture}
                                        fontSize={"larger"}
                                    />

                                </div>
                                <div class="news-column">
                                    
                                    <div className="latest-news-teaser">Espace personnel</div>
                                    <div className="news-text">
                                    Vous disposez d'un espace dédié et sécurisé auquel vous pouvez vous connecter à tous moments. Vous suivez l'évolution de votre requête, un historique vous présente les dates de soumissions et d'intercessions.<br/>
                                    Vous pouvez encourager les intercesseurs en les informant, via l'interface en ligne de la progression de votre demande d'intercession.<br/>
                                    </div>

                                    <NavLink to="/authentview">
                                        <Button renderAs="button">
                                        <span>Je me connecte...</span>
                                        </Button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* News */}
                    <div className="news-fluid-container">
                        <div className="news-container">
                            <div className="link-with-arrow">Nous soutenir</div>
                            {/* Latest news */}



                            <div className="news-columns-container">
                                <div className="news-column">
                                    <div className="latest-news-teaser">Nos besoins</div>                                    
                                    <div className="news-text">
                                        <p>Il y a une tâche importante à accomplir et « Ceux qui se réjouissent d'avoir la précieuse lumière de la vérité devraient désirer
                                        ardemment la répandre en tous lieux. »</p>
                                        <p>« Le monde est là qui attend d'être averti. Cette tâche nous a été confiée. Nous devons être attachés à la vérité quoi qu'il puisse
                                        nous en coûter. [...] Une grande œuvre doit être réalisée en un court laps de temps. Nous devons avoir une claire notion de notre
                                        travail et l'accomplir avec fidélité. [...] Le devoir de tout chrétien est de s'engager dans la croisade contre Satan, en brandissant
                                        la bannière ensanglantée de la croix du <b>Christ</b>.</p>
                                        <p>
                                        <b>La Croisade De La Prière</b> a une double vocation : le ministère de l’intercession et celui de l’évangélisation. Elle soutient l’Église
                                        dans sa mission à travers différentes actions spécifiques, des œuvres de bienfaisance, des formations, des séminaires, des études
                                        bibliques, des conférences, etc. Vous l’avez compris, la liste n’est pas exhaustive et les possibilités de servir <b>le Seigneur</b> sont
                                        innombrables. Nous sommes persuadés que <b>Dieu</b> utilisera des moyens simples, de manière inattendue, pour atteindre le monde.<br/>
                                        Voulez-vous participer à cette grande et belle aventure avec nous&nbsp;?</p>
                                        <p>
                                        Nous vous invitons à soutenir, financièrement, selon vos possibilités, le ou les projets de la liste ci-dessous pour l’avancement
                                        de l’œuvre de Dieu. Que chacun donne comme il l’a résolu en son cœur, sans tristesse ni contrainte ; car Dieu aime celui qui
                                        donne avec joie. <i>(2 Cor 9.7)</i> Vous trouverez ci-dessous plusieurs options pour soutenir ce ministère. Ensemble, nous ferons des
                                        exploits&nbsp;!</p>

                                        <p><i>Le trésorier</i></p>
                                    </div>
                                    <NavLink to="/socialshare">
                                        <Button renderAs="button">
                                        <span>J'apporte ma contribution...</span>
                                        </Button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
} 

export default AboutPage;