/*
DailyWorship Component
*/

import React, {Component, } from 'react';
import EgwDailyWorship from '../EgwDailyWorship/EgwDailyWorship'

class DailyWorshipText extends Component{
    constructor(props){
        super(props);
    }

    render = () => {
        return(
            <EgwDailyWorship {...this.props}/>
        )
    }
}

export default DailyWorshipText;