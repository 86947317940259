import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Table, Button, UncontrolledCarousel, Card, CardBody, Col, Row } from "reactstrap";
import {globals} from "../Globals/Globals"
import {CTimeStamp, CGUID, SyncPullSerializedUrl} from "datasync-common-library"
import {send_custom_emailV2} from 'datasync-mail';
import {GetDatasyncMediaOneBlock, GetFormValue3Tiers, SaveDataTierToDatasync, SyncDelete} from 'datasync-core'
import {DsSource} from "datasync-source"
import {Carousel} from "react-carousel-minimal";
import "./carousel-fix.css"

const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

class FilesRawTableDataSync2 extends Component{
    constructor(props){
        super(props);

        }
    
    componentDidMount = async () => {
    }

    mediaRendering = () => {
        if (!this.props.table ^ this.props.grid ^ this.props.carousel ^ this.props.carousel2)
            return(<h1><b>Too many props</b> set at same time. please choose between : <ul><li>table</li><li>grid</li><li>carousel</li><li>carousel2</li></ul></h1>)

        if (this.props.grid)  return this.gridRendering()
        else if (this.props.table) return this.tableRendering()
        else if (this.props.carousel) return this.carouselRendering()
        else if (this.props.carousel2) return this.carouselRendering2()
    }

    

    onEditMediaClickHandler = async (p_int_index_in_array) => {        
        //Trigger Edit on parent view
        if (this.props.onEditMediaHandler)
                this.props.onEditMediaHandler(this.props.media_list[p_int_index_in_array])
        else{
            console.log(`onEditMediaClickHandler : props.onEditMediaHandler unset p_int_index_in_array=${p_int_index_in_array}`)
        }
    }

    onOpenMediaClickHandler = async (p_int_index_in_array) => {        
        //Trigger Open viewer parent view
        if (this.props.onOpenMediaHandler)
                this.props.onOpenMediaHandler(this.props.media_list[p_int_index_in_array])
        else {
            console.log(`onOpenMediaHandler : props.onOpenMediaHandler unset p_int_index_in_array=${p_int_index_in_array}`)
        }
    }

    on_failed_handler = () => {
        alert("Save failed !")
    }             


    /**
     * Prototype : onDeleteMediaClickHandler
     * @param {*} mediaItemId 
     */
    onDeleteMediaClickHandler = async (mediaItemId) => {
        try{
            if (globals.parameters.debugging){
                console.clear();
                console.log("15:onDeleteMediaClickHandler");
                console.log("mediaItemId =>",mediaItemId)
                console.log("this.props.media_list[depositItemId] =>",this.props.media_list[mediaItemId])                    
            }

            if (window.confirm("Voulez-vous supprimer ce Média"))
            SyncDelete({
                datasync_url:globals.datasync_service.url,
                data_guid: this.props.media_list[mediaItemId].data_guid, 
                on_delete_result_callback:(p_s_data_guid, p_bool_success, p_s_error_message) => {
                    console.log(`onDeleteMediaClickHandler(p_s_data_guid=${p_s_data_guid}, p_bool_success=${p_bool_success?"true":"false"}, p_s_error_message=${p_s_error_message})`)
                    if (p_bool_success){
                        //Success
                        alert("Média supprimé")

                        //brodcast state list
                        if (this.props.onTerminate) {
                            this.props.onTerminate(this.props.carousel,this.props.media_list.filter((item, j) => j !== mediaItemId))
                        } else {alert("MediasRawTableDataSync::onTerminate is not defined !")}
                    }
                    else{
                        //Deletion failed
                        alert(`Erreur de suppression de Média ! err:${p_s_error_message}`)
                    }
              }
                });
          }
          catch(e){
            alert(e.message)
          }
    }

    tableRendering = () => {
        let table_body = null;
        console.log("15:tableRendering")
        if (!((this.props.media_list != null) && (this.props.media_list.length > 0))){
            table_body = <tr><td colSpan="4">Aucun média</td></tr>;
        }
        else{
            table_body = this.props.media_list.map((mediaItem,i)=>{
                let l_img_media = mediaItem.data_blob.data_tier.file_data
                console.log(`tableRendering[${i}] -> ${mediaItem}`)
                return  <tr key={i} className="thumbnail">
                        {!this.props.read_only &&
                        <td className="col_logo"><Button onClick={()=>{this.onDeleteMediaClickHandler(parseInt(i,10))}} style={{cursor:"pointer"}}>Effacer</Button></td>}
                        <td className="col_logo">
                            <img
                                alt="..."
                                className="img-circle img-no-padding img-responsive"
                                src={l_img_media}
                            />
                        </td>
                    </tr>});
                }

        return(
            <Table striped responsive={true}>
                <tbody>
                    {table_body}
                </tbody>
            </Table>
            );
    }


    gridRendering = () => {
        if (((this.props.media_list != null) && (this.props.media_list.length > 0) && (this.props.grid) ))
        return(
            <Row className="items-row">
                {
                    this.props.media_list.map((mediaItem,i)=>{
                        let l_img_media = mediaItem.data_blob.data_tier.file_data
                        
                        return <Col key={i} className="ml-auto" lg="4" md="6" sm="12">
                            <Card className="card-plain text-center">
                            <div className="card-image" onClick={()=>{this.onOpenMediaClickHandler(parseInt(i,10))}}>
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <img
                                    alt="..."
                                    className="img-rounded img-responsive img-hotel-immo-fixed-size"
                                    src={l_img_media}
                                />
                                </a>
                            </div>
                            </Card>
                        </Col>})
                }
            </Row>);
    }

    carouselRendering = () => {
        if ((this.props.media_list != null) && (this.props.media_list.length > 0)){ 
            return(
                <div className={`${this.props.className}`}>
                    <UncontrolledCarousel 
                        items={this.props.media_list} 
                        className="actions"
                        nextLabel={"Suivant"}
                        prevLabel={"Précédent"}
                        wrap={true}
                        fade={true}
                        pause={false}
                        variant={"dark"}
                        indicators={this.props.indicators}
                        controls={this.props.controls}
                        interval = {this.props.interval}/>
                </div>);
        }
        else return (<h1>Carousel rendering...</h1>)
    }
    carouselRendering2 = () => {
        if ((this.props.media_list != null) && (this.props.media_list.length > 0)){ 
            return(
                <div className={`${this.props.className}`}>
                    <Carousel
                        data={this.props.media_list}
                        //time={2500}
                        width="100%"
                        width_DISABLED="850px"
                        height_DISABLED="500px"
                        captionStyle={captionStyle}
                        radius="0px"
                        slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={true}
                        dots={true}
                        pauseIconColor="white"
                        pauseIconSize="40px"
                        slideBackgroundColor="#F6F6F6"
                        slideImageFit="fit"
                        thumbnails={true}
                        thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: "950px",
                            maxHeight: "900px",
                            margin: "10px auto",
                        }}
                        />
                </div>);
        }
        else return (<h1>Carousel rendering...</h1>)
    }

    render(){
        return (
            <>
                {(this.props.media_list==null) && <DsSource
                    one_block_pull = {false}
                    session_guid = {this.props.current_session}
                    datasync_url = {globals.datasync_service.url}
                    company_guid = {globals.datasync.jlrpt_company_guid} 
                    table_guid= {globals.datasync.media_table_guid}
                    seek_filter_clause = {this.props.user_filter}
                    
                    onAvailableItemsCount = {(available_items)=>{
                        if (this.props.debugging) console.log(`15:DataSyncSource::Available media:${available_items} with filter clause "${this.props.user_filter}"`)
                        //Trigger onFetchHandler
                        if (this.props.onFetchItems)
                            this.props.onFetchItems(available_items)
                    }}

                    onDataIsReady = {
                        (media_blob_list)=>{
                            if (this.props.debugging) console.log("15:on_media_ready_callback::media_blob_list=>", media_blob_list); 
                            if (this.props.debugging) console.log("15:onRenderTableCallback called")
                            if (this.props.onTerminate) {this.props.onTerminate((this.props.carousel || this.props.carousel2),media_blob_list)} else {alert("FilesRawTableDataSync2::onTerminate not defined")}}
                    }

                    onDataError = {
                        (error_message)=>{
                            alert(error_message)
                            //Trigger onFetchHandler
                            if (this.props.onFetchItems)
                                this.props.onFetchItems(0)
                        }
                    }
                    />}
                {this.mediaRendering()}
            </>);
    }
}


FilesRawTableDataSync2.propTypes = { 
    grid: PropTypes.bool,
    table: PropTypes.bool,
    carousel: PropTypes.bool,
    carousel2: PropTypes.bool
};

FilesRawTableDataSync2.defaultProps = {
    grid:true,
    table:false,
    carousel:false,
    carousel2:false
};


export default FilesRawTableDataSync2;
