/*
MailingView
*/

import React, {Component, useEffect, useState} from 'react';
import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router';

const debug = false


const MailingView = () => {
    let [RequeteUUID, setRequeteUUID] = useState(null)
    let history = useNavigate();
    let {uuidrequetes} = useParams();
    
    if (debug)
        console.log(`uuidrequetes = ${uuidrequetes}`)

    //Global service URL
    const localURL = 'http://localhost:4001'
    const remoteURL = 'https://requetes.jesusestlareponsepourtous.org'
    let requetes_micro_service_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL

    useEffect(()=>{
        console.log(`uuidrequetes=${uuidrequetes}, RequeteUUID=${RequeteUUID}`)

        if ((uuidrequetes != RequeteUUID) ){
            //Something has changed
            setRequeteUUID(uuidrequetes);//Caution, this will trigger useEffect again and again and again !
        }

        //Check uuidrequetes availability from database
        if (RequeteUUID){
            console.log("On fetch with RequeteUUID=", RequeteUUID)
                    fetch(`${requetes_micro_service_url}/req-get-lounge-data`,
                    {
                        headers: {
                            //'Content-Type': 'application/json'},
                            'Content-Type': 'text/javascript'},
                        method: 'POST',
                        body: JSON.stringify({uuidrequetes:RequeteUUID})
                    }).then(response => response.json())
                        .then(result => {
                            
                            console.log("result from fetch:", result)

                            if (result.items.length == 1){
                                //Redirect top  route user/:uuid 
                                console.log(`history.push(/userlounge/${RequeteUUID})`)   
                                history.push(`/userlounge/${RequeteUUID}`);
                            }
                        })
        }
        
    },[RequeteUUID])

    //rendering
    return (
        <div className="mailing-page-container">
            <div className="page-title">Connexion automatique à votre espace personnel</div>
            <div className="page-introduction">Nous tentons de vous authentifier et vous donnerons accès au suivi de votre requête de prière.</div>
             
            {debug &&
            <div>
                <h1>Mailing view</h1>
                <h2>uuidrequetes:{uuidrequetes}</h2>
            </div>
            }

            {!RequeteUUID &&
            <h2>Recherche en cours... Dossier non reconnu</h2>}
        </div>
        );
}

export default MailingView;