/*
AuthentView
*/

import React, {Component, useEffect, useState} from 'react';
import { Button } from 'reactstrap'
import {globals} from '../Components/Globals/Globals'
import { Document, Page,pdfjs } from 'react-pdf';
import a_booklet_pdf from '../Assets/pdf/fascicule_dec_2021.pdf'
import FilesRawTable  from '../Components/FilesRawTable/FilesRawTable'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BookletsView = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [currentMedia, setCurrentMedia] = useState(null);
    const [currentMediaTitle, setCurrentMediaTitle] = useState(null);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

      /**
     * Triggered as media is turned into edition from FilesRawTable component 
     */
    const onOpenMedia = async (selected_file_object) => {
      console.clear()
      console.log("onOpenMedia :: selected_file_object => ", selected_file_object)
      setPageNumber(1)
      setCurrentMedia(selected_file_object.file_data);
      setCurrentMediaTitle(selected_file_object.title)
    }

    //rendering  
    return (
      <>
        {(currentMedia != null) &&
          <div className='pdf-viewver'>
            <div className='pdf-top-bar'>
              <div className='pdf-navigator'>
                <div className='left-nav'>
                  <div>
                  <Button 
                        color='primary'
                        disabled = {pageNumber == 1}  
                        onClick={()=>{setPageNumber(pageNumber - 1)}}><span className='shrink'>&lt;</span><span className='wide'>Precédente</span></Button></div>

                  <div className='pdf-page-position'>
                    Page: {pageNumber} / {numPages}
                  </div>

                  <div>
                  <Button 
                        color='primary'
                        disabled = {pageNumber == numPages}  
                        onClick={()=>{setPageNumber(pageNumber + 1)}}><span className='wide'>Suivante</span><span className='shrink'>&gt;</span></Button> </div>
                </div>
      
                <div className='pdf-title'>
                  <Button onClick={()=>{setCurrentMedia(null)}}>Fermer<span className='wide'>&nbsp;{currentMediaTitle}</span></Button>
                </div>
              </div>
            </div>

            <Document file={currentMedia} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
          </div>}

        {(currentMedia == null) &&
        <div className="worship-page-container">
          {/* Full width main picture */}
          <div className="top-banner-image">
              <div className="nested-title dark">{globals.menu.booklets}</div>
          </div>

          <div className="body-page-container">
              {/* Laïus introduction de la page*/}
              <div className="welcome-title">
                  <div>
                      Prospectus et fascicules disponibles à la consultation...
                  </div>
              </div>

              <div className='events-fluid-container'>
                <div className='events-container'>
                  <FilesRawTable
                    readOnly={true}
                    onOpenMedia={onOpenMedia}
                    />
                </div>
              </div>

          </div>
        </div>}
      </>
    );
}

export default BookletsView;