import React from "react";
import {Button} from 'reactstrap';
import './SocialShare.css';
import { globals } from "../Globals/Globals";

export default function SocialShare (props) {
    const url = props.url?props.url:`https://jlrpt.com`;
    const jlrpt_description = props.description?props.description:`Je souhaite te partager ce verset biblique : ${globals.weekly.verse} (${globals.weekly.ref})`;

    var popupCenter = function(url, title, width, height){
        var popupWidth = width || 640;
        var popupHeight = height || 320;
        var windowLeft = window.screenLeft || window.screenX;
        var windowTop = window.screenTop || window.screenY;
        var windowWidth = window.innerWidth || document.documentElement.clientWidth;
        var windowHeight = window.innerHeight || document.documentElement.clientHeight;
        var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
        var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
        var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
        popup.focus();
        return true;
    };
    
    const WhatsAppHandler = (e) => {
        e.preventDefault();
        window.open(`whatsapp://send?text=${jlrpt_description}%0A${encodeURIComponent(url)}`)
    }

    const TwitterHandler = (e) => {
        e.preventDefault();
        
        var shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(document.title) +
            `&via=${jlrpt_description}` +
            "&url=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur Twitter");
    }

    const FaceBookHandler = (e) => {
        e.preventDefault();
        var shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur facebook");
    }

    const LinkedinHandler = (e) => {
        e.preventDefault();
        var shareUrl = "https://www.linkedin.com/shareArticle?url=" + encodeURIComponent(url);
        popupCenter(shareUrl, "Partager sur Linkedin");
    }

    return (
        <>
           
           {!props.single &&
            <div className="row">
                <div className="col-md-12">
                    <Button className="col-md-5 col-lg-2 whatsapp" onClick={WhatsAppHandler}>WhatsApp</Button>
                    <Button className="col-md-5 col-lg-2 twitter" onClick={TwitterHandler}>Twitter</Button>
                    <Button className="col-md-5 col-lg-2 facebook" onClick={FaceBookHandler}>FaceBook</Button>
                    <Button className="col-md-5 col-lg-2 linkedin" onClick={LinkedinHandler}>Linkedin</Button>
                </div>
            </div>}

            {props.single && 
            <Button className="col-md-5 whatsapp" onClick={WhatsAppHandler}>Je partage...</Button>} 
        </>
    )
}