import { render } from "@testing-library/react";
import React, {useRef, useEffect, useState } from 'react';
import {useParams} from "react-router-dom"
import axios from 'axios';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table';
import Etats2 from '../Etats2/Etats2';
import Thanks from "../Thanks/Thanks";
import './UserLounge.css';


const UserLounge = (props) => {
    const [RequeteData, setRequeteData] = useState(null);
    const [Etat, setEtat] = useState(null);
    const [nextEtat, setNextEtat] = useState(null);
    const [nextTitreEtat, setNextTitreEtat] = useState(null);
    const [prevUUID, setPrevUUID] = useState(null)
    let tableRef = useRef();
    let {uuidrequetes} = useParams();
    console.log("uuidrequetes from useParams => ", uuidrequetes)
    
    //Global service URL
    const localURL = 'http://localhost:4001'
    const remoteURL = 'https://requetes.jesusestlareponsepourtous.org'
    let requetes_micro_service_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL

    const localMailerURL = 'http://localhost:5100'
    const remoteMailerURL = 'https://mailer.jesusestlareponsepourtous.org'
    let mailer_service_db_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localMailerURL:remoteMailerURL

    useEffect(()=>{
      //Did uuidrequetes params changed ?
      if ((prevUUID != uuidrequetes) && (uuidrequetes != null)){
        setPrevUUID(uuidrequetes) //Store new uuidrequetes in order to detect next change
        fetchRequeteData(uuidrequetes)// 2DO fetch data oinly if props has changed
      }
    })

    const fetchRequeteData = async (uuidrequetes) => {
      //Load full requetes data relating on props.uuidrequetes
      //call requete-service with /req-get-lounge-data route
      const serviceResult = await axios.post(`${requetes_micro_service_url}/req-get-lounge-data`,{uuidrequetes:uuidrequetes});

      //Get service aknowledge before resetting state values.
      console.log("UserLounge::FetchRequeteData =>", serviceResult)

      if (serviceResult.data.state === true) {
          if (serviceResult.data.items.length == 1){
            //Request found, then Store Data
            setRequeteData(serviceResult.data.items[0])
            //Set other state values
            setEtat(serviceResult.data.items[0].idetats)
            setNextEtat(serviceResult.data.items[0].idetats)
          }
          else{
            //Request not found
            alert("Erreur : Requête non trouvée, veuillez vérifier la référence du dossier !")
          }
          
      }
      else{
          alert("Erreur:" + serviceResult.data.message);
      }
    }

    /**
     * Prototype : sendMailAcknowledges
     * Purpose : Send state change confirmation to user and Mail notification JLRPT staFF
     */
    const sendMailAcknowledges = async () => {
      try{
            //Post confirmation e-mail back to user
            let mailResult = await axios.post(`${mailer_service_db_url}/acknowledge-contact`,
                {
                    from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                    email:RequeteData.email,
                    subject:"Accusé de réception de votre demande de changement d'Etat de votre requête",
                    prenom:RequeteData.prenom,
                    nom:RequeteData.nom,
                }
            );

            console.log("mailResult =>", mailResult);
            //Display confirmation number
            alert(`Un courriel de confirmation vous a été envoyé`)

            //Broadcast user mail message to jlrpt mailing staff
            try{
                //Post confirmation e-mail back to user
                const broadcastResult = await axios.post(`${mailer_service_db_url}/mail-broadcast`,
                {
                  from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                  email:"jlrpt_contact_group@neuronal.bible",
                  email_user:RequeteData.email,
                  subject:"Changement d'Etat d'une requête JLRPT.com",
                  prenom:RequeteData.prenom,
                  nom:RequeteData.nom,
                  message:`Passage de l'Etat "${RequeteData.titre_etat_actuel}" à "${nextTitreEtat}"`
                }
                );

                console.log("broadcastResult =>", broadcastResult);
                //Display confirmation number
                console.log(`Courriel d'origine diffusé à l'équipe`)
            } 
            catch(err){
              alert(`Une erreur est survenue :${err}`);
              return;
            }
      } catch(err){
          alert(`Une erreur est survenue :${err}`);
          return;
      }
    }

    const UpdateRequeteStatus = async () => {
      //call requete-service
      var serviceResult = null
      try{
          axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
          serviceResult = await axios.put(`${requetes_micro_service_url}/req-upd-ett`,{idrequetes:RequeteData.idrequetes, idetats:nextEtat});
      } catch(err){
          this.toastError(`Verifiez votre connexion wifi : ${err}`);
          return;
      }
  
      //Get service aknowledge before resetting state values.
      if (serviceResult.data.state === true){
          //Refresh table
          if (tableRef.current)
            tableRef.current.onQueryChange();

          //Update current etat state in order to disable record Etat button
          setEtat(nextEtat);

          //Send user and JLRPT team acknowledge
          sendMailAcknowledges();
      }
      else{
          alert("Erreur:" + serviceResult.data.message);
      }
    }

    if (!RequeteData)
    return(
      <>Pas de données de requête...</>
    )
    else
    return(
      
      <div className="user-lounge-page-container">
        {/* Full width main picture */}
        <div className="top-banner-image">
            <div className="nested-title">Votre espace personnel</div>
        </div>

        <div className="body-page-container">
            {/* Welcome Panel */}
            <div className="welcome-title">
                <div>
                  Consultation de votre requête (reférence: {RequeteData.uuidrequetes.substring(0,8)})
                </div>
            </div>

            {/* Laïus introduction de la page*/}
            <div className="introduction-container">
                <p>Afin d’actualiser les informations que vous avez transmises, nous vous prions de bien vouloir prendre quelques minutes pour nous faire un retour concernant l’évolution de la situation, en choisissant l’option qui correspond</p>
            </div>

          <ModalHeader>
          {/*  Consultation de votre requête (reférence: {RequeteData.uuidrequetes.substring(0,8)})*/}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Etat actuel:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.titre_etat_actuel} type="text" placeholder="Votre prénom"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Pays du demandeur:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.titre_pays} type="text" placeholder="Votre prénom"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Prénom du demandeur:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.prenom} type="text" placeholder="Votre prénom"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Nom du demandeur:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.nom} type="text" placeholder="Votre nom"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Tel du demandeur:</label>
                <div className="col-sm-10">
                    <input readOnly={true} name="tel" className="form-control" value={RequeteData.tel} type="tel" placeholder="Votre numéro de téléphone"/>
                </div>
            </div>
            
            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Mail du demandeur:</label>
                <div className="col-sm-10">
                    <input readOnly={true} name="email" data-inputmask="'alias': 'email'" className="form-control" value={RequeteData.email} type="email" placeholder="Votre adresse de courriel"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Prénom du bénéficiaire:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.beneficiaire} type="text" placeholder="Prénom du bénéficiaire"/>
                </div>
            </div>

            <div className="form-group row">
                <label className="col-sm-2 col-form-label">Rubrique:</label>
                <div className="col-sm-10">
                    <input readOnly={true} className="form-control" value={RequeteData.titre_rubrique} type="text" placeholder="Votre prénom"/>
                </div>
            </div>

            <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Sujet de la prière:</label>
                  <div className="col-sm-10">
                      <textarea name="sujet_revise" className="form-control" value={RequeteData.sujet_revise} type="text" readOnly={true}/>
                  </div>
            </div>

            {/* ----------------- */}
            <hr/>
            <h5>Historique de soumission du sujet:</h5>
            <MaterialTable
                  tableRef={tableRef}
                  options ={{
                    grouping: false,
                    search: false,
                    showTitle: false,
                    paging: false,
                    sorting:false,
                  }}

                  columns={[
                    {
                      title: 'envois',
                      field: 'nb_envois'/*2DO Debug ,
                      render: rowData => (
                          this.envois_token(rowData.nb_envois, false)
                        )*/,
                    },
                    { title: 'Date', field: 'time_stamp' },
                    { title: 'Etat', field: 'titre_etat' }
                  ]}

                  data={query =>
                    new Promise((resolve, reject) => {
                      let url = `${requetes_micro_service_url}/req-hst-page?idrequetes=${RequeteData.idrequetes}`;

                      //-- pagination support
                      url += '&size=' + query.pageSize; //Set number of records by page
                      url += '&offset=' + (query.page) * query.pageSize //Compute first record offset for requested page

                      fetch(url)
                        .then(response => response.json())
                        .then(result => {
                          resolve({
                            data: result.items,
                            page: query.page,//Current page
                            //2DO totalCount: this.state.envois.length,
                            totalCount: result.items.length,
                          })
                        })
                    })
                  }
              />

            {/* ----------------- */}
            <hr/>
            <h5>Changer l'état de la requête:</h5>
            
            <p>A ce jour votre requête de prière est à l'état <b>{RequeteData.titre_etat_actuel}.</b><br/>
            Nous vous remercions de nous informer de tout changement d'état de votre sujet de prière. <br/>
            Si la requête a été exaucée, veuillez sélectionner l'état adequat ci-dessous :</p>
            <Etats2 
              OnClickEtat_callback = 
                {
                  (selected_etat) => {
                      setNextEtat(selected_etat.idetats);
                      setNextTitreEtat(selected_etat.titre_etat);
                    }
                }
              selected_idetats = {RequeteData.idetats}/>

            <br/>
            <Button color="primary" onClick={UpdateRequeteStatus} disabled={Etat == nextEtat}>Enregistrer le nouvel Etat</Button>

            {/* ----------------- */}
            <hr/>
            <h5>Remerciements:</h5>
            <p>Vous pouvez adresser vos remerciements à l'équipe d'intercession en remplissant le champ texte ci-dessous:</p>
            <Thanks idrequetes={RequeteData.idrequetes}/>
                        
          </ModalBody>
      </div>
      </div>
    );
}

export default UserLounge;