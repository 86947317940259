import React from "react"
import BibleForce from "../Components/BibleForce/BibleForce"
import {globals} from "../Components/Globals/Globals"

const BiblePageView = () => {
    return(
        <div className="bible-page-container">
            {/* Full width main picture */}
            <div className="top-banner-image">
                <div className="nested-title dark">{globals.menu.bible_read}</div>
            </div>

            <div className="body-page-container">
                {/* Laïus introduction de la page*/}
                <div className="welcome-title">
                    <div>
                        Etudions ensemble la Parole de Dieu…
                    </div>
                </div>

                <div className='bible-container'>
                    <BibleForce/>
                </div>                
            </div>
        </div>
    )
}

export default BiblePageView;