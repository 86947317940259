
/*
RequeteForm2 Component, based on InputForm one
*/
import React, {Component} from 'react';
import Rubriques3 from '../Rubriques3/Rubriques3';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './RequeteForm2.css';
import ModalSubmit2 from '../ModalSubmit2/ModalSubmit2';
import InputForm2 from '../InputForm2/InputForm2';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import {globals} from '../Globals/Globals'

class RequeteForm2 extends Component{
    constructor(props){
        super(props)

        this.clearObject = {
            has_changed : false,
            country_code:"FR",
            country_calling_code:"+33",
            nom:"",
            prenom:"",
            tel:"",
            email:"",
            sujet:"",
            beneficiaire:"",
            idrubriques:null,
            rgpd:false,
            err_nom:[], err_prenom:[], err_email: [], err_tel: [], err_sujet:[], err_beneficiaire:[], err_rgpd:[],err_idrubriques:[],
            selected_rubrique_object : null,
            rgpd_expended:false,
            RGPDtitle:`J'accepte les conditions`,
            RGPDtext:`En cochant cette case et en soumettant ce formulaire, j’accepte que mes données personnelles soient utilisées par La Croisade De La Prière pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire. 
            \r\nPour connaître et exercer vos droits, notamment de retrait de votre consentement vis-à-vis de l’utilisation des données collectées dans ce formulaire, veuillez consulter notre politique de confidentialité.` + `\r\n\r\nEn tant qu'association Loi 1901, nous nous conformons au Règlement Général Européen sur la Protection des données (RGPD) et nous vous assurons la confidentialité, la protection et la sécurisation de vos données personnelles.`,
            dossier:{uuidrequetes_log:""},
            short_uuid:"",
            debug:false
        }
        this.state = this.clearObject;

        this.rgpdCheckBoxRef = React.createRef();
    }

    clearForm = () => {
        //Reset state
        if (!this.state.debug){
            this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5)})
            this.setState(this.clearObject);
        }
    }

    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }

    componentDidMount = () =>{
        this.clearForm()
        this.handle_nom = this.handle_nom.bind(this);
        this.handle_prenom = this.handle_prenom.bind(this);
        this.handle_tel = this.handle_tel.bind(this);
        this.handle_sujet = this.handle_sujet.bind(this);
        this.handle_email = this.handle_email.bind(this);
        this.handle_beneficiaire = this.handle_beneficiaire.bind(this);
        this.onChangeCheckboxRGPD = this.onChangeCheckboxRGPD.bind(this);
        this.onClickCheckboxRGPD = this.onClickCheckboxRGPD.bind(this);

        if (this.state.debug){
            this.setState({
            has_changed:true,
            country_code:"FR",
            country_calling_code:"+33",
            nom:"Leon",
            prenom:"Cal",
            tel:"+33749690652",
            //tel:"",
            email:"pmabiala@icloud.com",
            sujet:"Test avec index 33 pré-saisi , covid-19, 1 poste de remplacement , Jean 3.16 - Smile😀🙏🏽🙏🏽🙏🏽🇦🇫 🇦🇽Prions pour elle’",
            beneficiaire:"Casimir",
            idrubriques:1,
            rgpd:true});
        }
    }

    surroundedValidPhoneNumber = aPhoneNumber => {
        let phoneNumberIsValid = false;
        try{
            if (this.state.debug)
                console.log(`aPhoneNumber=${aPhoneNumber} - length:${aPhoneNumber.length} - isValidPhoneNumber:${isValidPhoneNumber(aPhoneNumber)?"true":"false"} - country_calling_code : ${this.state.country_calling_code}`)
            if ((aPhoneNumber.length == 0) || (aPhoneNumber == this.state.country_calling_code)) phoneNumberIsValid = true;
            else phoneNumberIsValid = isValidPhoneNumber(aPhoneNumber)
        }
        catch(e){
            phoneNumberIsValid = true
            console.error(`surroundedValidPhoneNumber raised $(e) with phone number ${aPhoneNumber}`)
        }
        return phoneNumberIsValid
    }

    checkValidation = (fieldName, read_only = false) => {
        
        let max = 45
        let min = 2
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = this.state[errorsFieldName]
        let value = this.state[fieldName]
        nextErrors = []

        switch (fieldName) {
            case "nom" :     
                min = 2           
                max = 45
                
                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "prenom" :
                min = 2
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "email" :
                min = 5
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

                if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`non valide`)
                break

            case "tel" :
                const phone_is_required = false
                const l_sPhoneError = (this.state[fieldName] ? (this.surroundedValidPhoneNumber(this.state[fieldName]) ? "" : 'Téléphone invalide') : (phone_is_required?'Téléphone obligatoire':''))
                if (l_sPhoneError){
                    nextErrors.push(l_sPhoneError)
                }
                break

            case "sujet" :
                min = 10
                max = 400

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "beneficiaire" :
                min = 2
                max = 40

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "captcha" :
                min = 1
                max = 2

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                    nextErrors.push(`calcul faux !`)
                break

            case "idrubriques" :
                if (!this.state.idrubriques)
                    nextErrors.push(`obligatoire !`)
                break

            case "rgpd" :
                if (!this.state.rgpd)
                    nextErrors.push(`obligatoire !`)
                break
            default:
                console.error(`checkValidation : champ ${fieldName} non supporté`)
        }

        if (globals.parameters.debugging){
            console.clear()
            console.log(`-----------------`)
            console.log(`read_only => ${read_only}`)
            console.log(`fieldName => ${fieldName}`)
            console.log(`value => ${value}`)
            console.log(`errorsFieldName => ${errorsFieldName}`)
            console.log(`nextErrors => ${nextErrors}`)
            console.log(`nextErrors.length => ${nextErrors.length}`)
        }
        
        //update error field
        nextState[errorsFieldName] = nextErrors

        //set change flag
        nextState.has_changed = true

        //update internal state
        if (!read_only) //Avoid recursive setstate
            this.setState(nextState)

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    handle_nom = e => {
        e.preventDefault();

        this.setState({nom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_prenom = e => {
        e.preventDefault();

        this.setState({prenom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_tel = (newtel) => {
        //Update tel value from form field
        this.setState({did_not_change:false, tel:newtel}, () => {
            /* Check phone number right now */
            this.checkValidation("tel")
        });
    }

    handle_country = (newCountry) => {
        //Update country-code value from form field
        this.setState({
                        did_not_change:false, 
                        country_code:newCountry, 
                        country_calling_code:`+${getCountryCallingCode(newCountry)}`}, 
                        ()=>{if (this.state.debug) console.log(`tel as country changed =>${this.state.tel} / ${this.state.country_calling_code}`)});
    }

    handle_sujet = (e) => {
        e.preventDefault();
        //Update sujet value from form field
        this.setState({sujet:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_email = (e) => {
        //Update name value from form field
        this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_beneficiaire = (e) => {
        //Update beneficiaire value from form field
        this.setState({beneficiaire:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    onChangeCheckboxRGPD = (e) => {
        this.setState({rgpd:e.target.checked?1:0}, ()=>{this.checkValidation("rgpd")});
    }

    onClickCheckboxRGPD = (e) => {
        this.setState(
                        {rgpd:!this.rgpdCheckBoxRef.current.checked?1:0}, 
                        ()=>{this.checkValidation("rgpd")});
    }

    _OnClickRubrique_callback = (selected_rubrique) => {
        this.setState({idrubriques:selected_rubrique.idrubriques, selected_rubrique_object : selected_rubrique}, 
            () => {
                this.checkValidation("idrubriques")
            });
    }

    onCheckFormValidationBeforeSubmit = () => {
        //Force all fields check
        let canSubmit = this.checkValidation('nom')
        canSubmit &= this.checkValidation('prenom')
        canSubmit &= this.checkValidation('tel')
        canSubmit &= this.checkValidation('email')
        canSubmit &= this.checkValidation('beneficiaire')
        canSubmit &= this.checkValidation('sujet')
        canSubmit &= this.checkValidation('rgpd')
        canSubmit &= this.checkValidation('idrubriques')

        return (canSubmit);
    }

    formRendering = () => {
        return(
            <div>
                    <form id="requete-form" onSubmit={this.onFormSubmitHandler}> 
                        <div className="form-group row col-md-12">
                            <InputForm2 
                                label={"Prénom"} 
                                name={"prenom"} 
                                value={this.state.prenom} 
                                onChange={this.handle_prenom} 
                                type={"text"} 
                                placeholder={"Votre prénom"}
                                error={this.state.err_prenom}
                            />

                            <InputForm2 
                                label={"Nom"} 
                                name={"nom"} 
                                value={this.state.nom} 
                                onChange={this.handle_nom} 
                                type={"text"} 
                                placeholder={"Votre nom"}
                                error={this.state.err_nom}
                            />
                        </div>

                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-md-6 col-form-label">Téléphone (optionnel):</label>
                                <div className="col-md-10">
                                    <PhoneInput
                                        smartCaret={false}
                                        placeholder="Numéro de téléphone (optionnel)"
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="FR"
                                        value={this.state.tel}
                                        onChange={(tel)=>{this.handle_tel(tel);}}
                                        error={() => {console.clear();console.error("error =>", this.surroundedValidPhoneNumber(this.state.tel))}}
                                        onCountryChange={(country) => {this.handle_country(country);}}
                                        />
                                </div>
                                <label className="form-error">
                                    {this.state.err_tel.join(", ")} 
                                </label>
                            </div>
                            
                        
                            <InputForm2 
                                label={"email"} 
                                name={"email"} 
                                value={this.state.email} 
                                onChange={this.handle_email} 
                                type={"text"} 
                                placeholder={"Votre adresse e-mail"}
                                error={this.state.err_email}
                            />                        
                         </div>
                       
                       <div className="form-group row col-md-12">
                            <InputForm2 
                                label={"Bénéficiaire"} 
                                name={"beneficiaire"} 
                                value={this.state.beneficiaire} 
                                onChange={this.handle_beneficiaire} 
                                type={"text"} 
                                placeholder={"Prénom du bénéficiaire"}
                                error={this.state.err_beneficiaire}
                            />                        

                            <div className="col-md-6">
                                <label className="col-md-2 col-form-label">Rubrique:</label>
                                <div className="col-md-10">
                                    <Rubriques3 selected_rubrique_object = {this.state.selected_rubrique_object} OnClickRubrique_callback = {this._OnClickRubrique_callback}/>
                                    <label 
                                        className="form-error">
                                        {this.state.err_idrubriques.join(", ")} 
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row col-md-12">
                            <div className="col-md-6">
                                <label className="col-md-6 col-form-label">Sujet de la prière:</label>
                                <div className="col-md-10">
                                    <textarea
                                        className="sujet-text-area" 
                                        name="sujet" 
                                        value={this.state.sujet} onChange={(e)=>{this.handle_sujet(e);}} 
                                        type="text" 
                                        placeholder="Détaillez ici le sujet de la prière"/>
                                    <label 
                                        className="form-error">
                                        {this.state.err_sujet.join(", ")} 
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="col-form-label"> </label>
                                <div className="col-md-12">
                                    <input
                                        className="" 
                                        ref={this.rgpdCheckBoxRef} 
                                        type="checkbox" 
                                        checked={this.state.rgpd===1?true:false} 
                                        onChange={(e)=>{this.onChangeCheckboxRGPD(e);}}/>
                                        
                                        <label id="rgpd_title_label" onClick={(e)=>{this.onClickCheckboxRGPD(e);}}>{this.state.RGPDtitle}</label>
                                        <Button onClick={()=>{this.setState({rgpd_expended:!this.state.rgpd_expended})}}>{this.state.rgpd_expended?"Réduire les RGPD":"Détailler les RGPD"}</Button>
                                        {this.state.rgpd_expended &&
                                            <label id="rgpd_text_label" onClick={(e)=>{this.onClickCheckboxRGPD(e);}}>{this.state.RGPDtext}</label>}
                                        <label 
                                            className="form-error">
                                            {this.state.err_rgpd.join(", ")} 
                                        </label>
                                </div>
                            </div>
                        </div>

                    </form>
                <ModalFooter>
                    <label className="col-sm-4 col-form-label" hidden={this.state.short_uuid.length < 8}>Requête soumise sous la référence: <strong>{this.state.short_uuid}</strong></label>

                    <Button color="secondary" onClick={(e) => {this.clearForm();}} hidden={!this.state.has_changed}>Tout effacer</Button>
                    <ModalSubmit2
                        color={"primary"}
                        caption={"Soumettre la requête de prière"}
                        className="modal-full-screen"
                        onCheckFormValidationBeforeSubmit={this.onCheckFormValidationBeforeSubmit}
                        data = {this.state}
                        onFailed={()=>{console.log("Submission failed...")}}
                        onSuccess={()=>{console.log("Submission succedeed..."); this.clearForm();}} 
                        disabled={false}>Soumettre la requête de prière</ModalSubmit2>
                </ModalFooter>
            </div>
        )
    }

    render = () => {
        return (
            <div>
                {this.state.debug && <h1 style={{color:'red'}}>DEBUGGING MODE</h1>}
                {this.formRendering()}
            </div>
            );
    }
}

export default RequeteForm2;