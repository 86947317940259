import React, { Component } from 'react'
import NestedTitlePicture from "../NestedTitlePicture/NestedTitlePicture"
import {Route, NavLink, Switch, BrowserRouter, useNavigate} from "react-router-dom"
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

import SpiritualitePicture from '../../Assets/img/spirituality.jpg'
import SantePicture from '../../Assets/img/healthy.jpg'
import SocialPicture from '../../Assets/img/social.jpg'
import MissionPicture from '../../Assets/img/mission.jpg'

class Acteurs extends Component{
    constructor(props){
        super(props)
        this.state={partner_list:null};
        this.callToAction = "Je soumets mon sujet ..."
    }

    staticRender = () => {
        let title_color = "white"
        let title_font_size = "larger"
        let container_width = "100%"
        let container_height = "200px"

        return (
            <div className="rubriques-columns-container">
                <div className="rubrique-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Spiritualité"}
                        pictureURL={SpiritualitePicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="rubrique-description">
                    Vous êtes témoin d'une faiblesse spirituelle, d'une connaissance. Il vous tient à coeur de la remettre entre les mains du Seigneur&nbsp;?
                    </div>
                </div>


                <div className="rubrique-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Santé"}
                        pictureURL={SantePicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="rubrique-description">
                    Vous souhaitez que l'on prie pour un malade, ou une personne dont la santé est fragile&nbsp;?
                    </div>
                </div>

                <div className="rubrique-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Social"}
                        pictureURL={SocialPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="rubrique-description">
                    Un ami ou un proche est à la recherche d'un emploi ou rencontre des difficultés d'insertion dans notre société&nbsp;?
                    </div>
                </div>

                <div className="rubrique-column">
                    <NestedTitlePicture 
                        width={container_width}
                        height={container_height}
                        pictureTitle = {"Mission"}
                        pictureURL={MissionPicture}
                        color={title_color}
                        fontSize={title_font_size}
                        />
                    <div className="rubrique-description">
                    Vous connaissez un pasteur ou tout autre personne qui s'apprête à tout quitter pour servir le Seigneur&nbsp;?
                    </div>
                </div>
                
                <div className="w-100"></div>
                <div className="rubrique-column spacing-button">
                    <NavLink to="/priere">
                        <Button renderAs="button">
                        <span>{this.callToAction}</span>
                        </Button>
                    </NavLink>

                    <NavLink to="/authentview">
                        <Button renderAs="button">
                        <span>Je me connecte...</span>
                        </Button>
                    </NavLink>
                </div>
            </div>
        )
    }

    render = () => {
        return(
            this.staticRender()
        )
    }
} 

export default Acteurs;